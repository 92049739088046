@import '../_config/config.scss';

.box{
    display:flex;
    justify-content: center;
    padding : 10px;
    @include tablet{
        flex-direction:column;
    }
    @include mobile{
        flex-direction:column;
    }
}

.card {
    margin:5px;
    @include desktop{
        width:40vw;
    }
}

.list{
    margin-left:5px;
}

.list > li{
    font-size:13px;
    margin-top:5px;
}