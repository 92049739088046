@import '../_config/config.scss';
@import '../_config/color.scss';

.main {
    @include desktop {
        width: 70%;
    }
    @include tablet {
        width: 85%;
    }
    @include mobile {
        width: 95%;
    }
    margin: 0 auto;
}

.title {
    margin-top: 50px;
    text-align: center;
    font-size: 2.5vh;
    font-weight: bold;
}

.desc {
    text-align: center;
    padding: 5px;
}

.a {
    text-decoration: none;
    color: black;
    &:hover {
        color: #4e4e4e;
    }
}

.rank-wrapper{
    margin-top: 30px;
    width:100%;
    display: flex;
    flex-direction: row;
    column-gap: 10%;
    justify-content: center;
    & .solved-rank, & .distribution-rank {
        width:40%;
        text-align: center;
    }
    & .rank-title {
        font-weight: 900;
        font-size: 2vh;
        border-bottom: 1px solid gray;
    }
}

.item {
    border-bottom: dashed 1px gray;
    height: 5vh;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    @include desktop {
        font-size: 2.5vh;
    }
    @include tablet {
        font-size: 2vh;
    }
    @include mobile {
        font-size: 3.5vw;
    }

    & .icon {
        @include desktop {
            font-size: 10vh;
        }
        @include tablet {
            font-size: 7vh;
        }
        @include mobile {
            font-size: 7vw;
        }
    }
    &.first .icon, &.first .rank{
        color: $gold;
    }
    &.second .icon, &.second .rank{
        color: $silver;
    }
    &.third .icon, &.third .rank{
        color: $bronze;
    }

    & .rank {
        width: 20%;
        text-align: center;
        font-weight:800;
    }
    & .name {
        width: 60%;
        text-align: center;
    }
    & .cnt {
        width: 20%;
        text-align: center;
        font-weight:800;
    }
}

.other {
    display: flex;
    justify-content: center;
}

.button {
    margin: 3px;
    width: 48%;
    border:none;
    background-color:white;
    height:50px;
    font-size:1.5rem;
    &.selected{
        border-bottom:solid 3px black;
        transition:.2s;
    }
}

.buttonSet {
    display:flex;
    justify-content: center;
    margin: 0 auto;
}
