@import '../../_config/config.scss';

.list {
    height: 5vh;
    font-size: 2vh;
    display: flex;
    align-items: center;
}

.name {
    width: 40%;
    text-align: center;
    display:flex;
    justify-content: center;
    // background-color: yellow;
}

.value {
    width: 60%;
    text-align: center;
    // background-color: green;
}

.a:nth-child(2n) > .list {
    background-color: #ffffff;
}

.title {
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    font-weight: 700;
    background-color: #aaaaaa;
}

.a {
    text-decoration: none;
    color: black;
}

.a:hover {
    color: #555555;
}

.common {
    @include desktop {
        width: 50vw;
        margin: 20px auto;
    }
    @include tablet {
        width: 80vw;
        margin: 15px auto;
    }
    @include mobile {
        width: 85vw;
        margin: 10px auto;
    }
}

.header {
    @include desktop {
        font-size: 2vw;
    }
    @include tablet {
        font-size: 3vh;
    }
    @include mobile {
        font-size: 3vh;
    }
    font-weight: 700;
}

.image {
    @include desktop {
        width: 1.5vw;
    }
    @include tablet {
        width: 2vh;
    }
    @include mobile {
        width: 2vh;
    }
    margin-right: 20px;
}

.all{
    @include desktop{
        font-size:1.2vw;
    }
    text-align: center;
    padding:10px;
    font-weight:bold;
}

.id{
    width:50px;
}