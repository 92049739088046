@import '../_config/config.scss';

.main {
    margin: 10px auto 0;
    @include desktop {
        width: 55vw;
    }
    @include tablet {
        width: 95vw;
    }
    @include mobile {
        width: 95vw;
    }
}

.item{
    background-color: #e0e0e0;
    padding: 0;
    font-weight: 500;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
}

.ul {
    padding: 0;
}

.list {
    height: 5vh;
    font-size: 2vh;
    display: flex;
    align-items: center;
}

.name {
    width: 40%;
    text-align: center;
}

.value {
    width: 60%;
    text-align: center;
}

.ul > .list:nth-child(2n) {
    background-color: #ffffff;
}
