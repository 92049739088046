@import '../../_config/config.scss';

.list {
    height: 5vh;
    font-size: 2vh;
    display: flex;
    align-items: center;
}

.name {
    width: 40%;
    text-align: center;
    @include mobile{
        word-break: keep-all;
    }
}

.value {
    width: 60%;
    text-align: center;
}

.list:first-child{
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    font-weight:700;
    background-color:#aaaaaa;
}

.list:nth-child(2n){
    background-color:#ffffff;
}

.link{
    text-decoration: none;
    color:black;
}