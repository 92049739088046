@import '../_config/config.scss';

.main {
    margin: 10px auto 0;
    @include desktop {
        width: 55vw;
    }
    @include tablet {
        width: 95vw;
    }
    @include mobile {
        width: 95vw;
    }
}

.container {
    display: flex;
}

.group {
    margin-left: 20px;
}

.title {
    width: 10%;
    margin: 10px;
    text-align: center;
    font-size: 1.3rem;
    vertical-align: middle;
    height: 40px;
    line-height: 40px;
    font-weight: bold;
    @include mobile {
        margin: 2px 6px;
        font-size: 1rem;
    }
}

button {
    width: 15%;
    margin: 10px;
    text-align: center;
    font-size: 1.2rem;
    border-style: none;
    height: 40px;
    background-color: white;
    border-radius: 20px;
    @include mobile {
        margin: 2px;
        width: 20%;
        font-size: 0.9rem;
    }
}

.selected {
    background-color: #bbbbbb;
}
