@import '../_config/config.scss';

.main {
    background-color: black;
    height: 5vh;
}

.list {
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
}

.link{
    text-decoration: none;
    @include desktop{
        width:30%;
    }
    @include tablet{
        width:40%;
    }
    @include mobile{
        width:50%;
    }
}

.li {
    text-align: center;
    font-size: 2vh;
    color:white;
}

.link:hover{
    border-bottom:medium solid white;
}