@import '../_config/config.scss';

.main {
    margin:0 auto;
    @include desktop{
        display: flex;
        height: 10vh;
    }
    @include tablet{
        width:50vw;
        height:17vh;
    }
    @include mobile{
        height:17vh;
        width:70vw;
    }
}

.title {
    font-family: 'AlexBrush';
    font-size: 8vh;
}

.link {
    text-decoration: none;
    color: black;
    @include desktop {
        margin-left: 20vw;
    }
    @include tablet {
        display:block;
        text-align:center;
    }
    @include mobile {
        text-align:center;
        display:block;
    }
}

.link:hover {
    color: black;
}

.a {
    text-decoration: none;
    color: rgb(31, 31, 31);
}

.a:hover {
    color: #555;
}

.ul {
    display: flex;
    align-items: center;
    list-style: none;
    height: 100%;
    @include desktop{
        margin: 0;
        padding: 0;
        text-align: center;
        margin-left: 40vw;
    }
    @include mobile{
        width:100%;
    }
    @include tablet{
        width:100%;
    }
}

.list {
    text-align : center;
    @include desktop{
        font-size: 1.7vh;
        margin: 0 20px;
    }
    @include mobile{
        width:30%;
    }
    @include tablet{
        width:30%;
    }
    
    padding: 0;
    border: 0;
}