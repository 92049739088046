@import '../_config/config.scss';

.box {
    @include desktop{
        width: 38vw;
    }
    display: flex;
    flex-direction: column;
}

.title {
    height: 5vh;
    text-align: center;
    font-size: 2.5vh;
    font-weight: bold;
    padding-top: 10px;
}

.body {
    display: flex;
}